<template>
  <div class="loading bar">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
export default {
  name: "Loader",
};
</script>
<style scoped>
.loading {
  position: relative;
}

.bar > div {
  width: 2px;
  height: 8px;
  position: absolute;
  top: -4px;
  opacity: 1;
  -webkit-animation: fadeit 0.6s linear infinite;
  animation: fadeit 0.6s linear infinite;
}
.bar > div:nth-child(1) {
  background: #fffff0;
  -webkit-transform: rotate(0deg) translate(0, -10px);
  transform: rotate(0deg) translate(0, -9px);
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.bar > div:nth-child(2) {
  background: #fffff0;
  -webkit-transform: rotate(30deg) translate(0, -30px);
  transform: rotate(30deg) translate(0, -9px);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.bar > div:nth-child(3) {
  background: #fffff0;
  -webkit-transform: rotate(60deg) translate(0, -30px);
  transform: rotate(60deg) translate(0, -9px);
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.bar > div:nth-child(4) {
  background: #fffff0;
  -webkit-transform: rotate(90deg) translate(0, -30px);
  transform: rotate(90deg) translate(0, -9px);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.bar > div:nth-child(5) {
  background: #fffff0;
  -webkit-transform: rotate(120deg) translate(0, -30px);
  transform: rotate(120deg) translate(0, -9px);
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.bar > div:nth-child(6) {
  background: #fffff0;
  -webkit-transform: rotate(150deg) translate(0, -30px);
  transform: rotate(150deg) translate(0, -9px);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.bar > div:nth-child(7) {
  background: #fffff0;
  -webkit-transform: rotate(180deg) translate(0, -30px);
  transform: rotate(180deg) translate(0, -9px);
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.bar > div:nth-child(8) {
  background: #fffff0;
  -webkit-transform: rotate(210deg) translate(0, -30px);
  transform: rotate(210deg) translate(0, -9px);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.bar > div:nth-child(9) {
  background: #fffff0;
  -webkit-transform: rotate(240deg) translate(0, -30px);
  transform: rotate(240deg) translate(0, -9px);
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.bar > div:nth-child(10) {
  background: #fffff0;
  -webkit-transform: rotate(270deg) translate(0, -30px);
  transform: rotate(270deg) translate(0, -9px);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.bar > div:nth-child(11) {
  background: #fffff0;
  -webkit-transform: rotate(300deg) translate(0, -30px);
  transform: rotate(300deg) translate(0, -9px);
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}
.bar > div:nth-child(12) {
  background: #fffff0;
  -webkit-transform: rotate(200deg) translate(0, -30px);
  transform: rotate(330deg) translate(0, -9px);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes fadeit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes fadeit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
